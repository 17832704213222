import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import usePagination from "../../../hook/usePagination"
import Service from "../../../service/sendRequest"
import useRmaStatus from "../../../hook/useRmaStatus"
import { iRmaPostViewPage } from "../../../util/types"
import { Avatar, Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Field, Label, SearchBox, Select, Spinner, TableCellLayout, TableColumnDefinition } from "@fluentui/react-components"
import { Alert, Pagination } from "antd"
import { STATUS_RMA_CENTER_DEFAULT_DRAFT, STATUS_RMA_CENTER_DEFAULT_SHIPPED } from "../../../util/const"
import ManagerView from "../../manager/view/manager-view"
import { useTranslation } from "react-i18next"
import { useOnMountUnsafe } from "../../../hook/useOnMountUnsafe"
import LayoutPage from "../../../component/ui/layout-page-component"
import ViewerPdf from "../../manager/generating-pdf/viewer-pdf"

export default function RMAPage() {
    const { hashStatus } = useRmaStatus()
    const { t } = useTranslation()

    const [openViewPost, setOpenViewPost] = useState(false)
    const [postIdSelected, setPostIdSelected] = useState(-1)

    const [selectRMA, setSelectRMA] = useState(-1)

    // * Implementation Searching v.2
    const inputRef = useRef('')
    const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>()
    const [selectStatus, setSelectStatus] = useState('-1')
    const [selectFilter, setSelectFilter] = useState('f_n')
    const [optionSearch, setOptionSearch] = useState('')
    const [searchInput, setSearchInput] = useState('')
    const [data, setData] = useState<iRmaPostViewPage[]>([])
    const [loading, setLoading] = useState(true)
    const [limit] = useState(20)
    const [skip, setSkip] = useState(0)
    const [skipPagination, setSkipPagination] = useState(0)
    const [total, setTotal] = useState(0)


    const handleSearch = useCallback(() => {
        searchInformation(limit, skip, optionSearch)
    }, [limit, skip, optionSearch])

    const updatePagination = async (skip: number) => {
        setSkipPagination(() => skip)
        const newSkip = (skip - 1) * limit
        setSkip(() => newSkip)
        const options = `&${selectFilter}=${searchInput}&s=${selectStatus}`
        searchInformation(limit, newSkip, options)
    }

    const searchInformation = useCallback(async (limit: number, skip: number, option: string = '') => {
        try {
            setLoading(() => true)
            const res = await Service.getRmaAdminManager(limit, skip, option)
            if (res?.data) {
                setData(() => res.data)
                setTotal(() => res.total)
            }
        } catch (error) {
            console.log('search-error: ', error)
        } finally {
            setLoading(() => false)
        }
    }, [limit, skip])

    useOnMountUnsafe(() => {
        searchInformation(limit, skip) //initial
    })

    const handleFilter = (filter: string) => {
        setSkip(() => 0)
        setSkipPagination(() => 1)
        setSelectFilter(() => filter)
        if (filter === "f_rc") {
            setSelectStatus(() => "-1")
        }
        const options = `&${filter}=${searchInput}&s=${selectStatus}`
        setOptionSearch(() => options)
        searchInformation(limit, 0, options)
    }

    const handleStatus = (status: string) => {
        setSkip(() => 0)
        setSkipPagination(() => 1)
        setSelectStatus(() => status)
        const options = `&${selectFilter}=${searchInput}&s=${status}`
        setOptionSearch(() => options)
        searchInformation(limit, 0, options)
    }

    const onChangeInput = (event: any) => {
        const search = (event.target.value || '') as string

        setSkip(() => 0)
        setSkipPagination(() => 1)
        setSearchInput(() => search)
        const options = `${search.length !== 0 ? `&${selectFilter}=${search}` : ''}&s=${selectStatus}`.replaceAll(' ', '')
        setOptionSearch(() => options)
    }

    const renderOptionStatus = useMemo(() => Object.values(hashStatus)
        .map(({ id, status }) => (
            <option key={2} value={id}>{status}</option>
        )), [hashStatus])


    useEffect(() => {
        if (inputRef.current === searchInput) return
        if (currentTimeout) clearTimeout(currentTimeout)

        const newTimeout = setTimeout(() => {
            setSkip(() => 0)
            searchInformation(limit, 0, optionSearch)
            inputRef.current = searchInput
        }, 1000)

        setCurrentTimeout(newTimeout)
        return () => clearTimeout(newTimeout)
    }, [searchInput])

    const placeholderMemo = useMemo(() => {
        let message = "Search by "
        switch (selectFilter) {
            case 'f_rc':
                message += "RMA number example: 2024000000"
                break;
            case 'f_n':
                message += "name"
                break;
            case 'f_e':
                message += "email"
                break;
            default:
                message = 'Search by name or email or RMA code'
        }
        return message
    }, [selectFilter])


    // * print RMA
    const handlePrintRma = (idr: number) => {
        setSelectRMA(() => idr)
    }


    const columns: TableColumnDefinition<iRmaPostViewPage>[] = useMemo(() => [
        createTableColumn<iRmaPostViewPage>({
            columnId: 'name',
            renderHeaderCell: () => {
                return <span className="font-semibold">Name</span>
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout
                        media={
                            <Avatar
                                // aria-label={item.user?.name}
                                name={item.user?.name}
                            />
                        }
                    >
                        <div className="flex flex-col gap-0">
                            <span className="font-semibold"> {item.user?.name}</span>
                            <span className="hidden xl:block"> {item.user?.email}</span>
                        </div>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: 'information',
            renderHeaderCell: () => {
                return <span className="font-semibold">Information</span>;
            },
            renderCell: (item) => {
                return item.information
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: 'status',
            renderHeaderCell: () => {
                return <span className="flex w-full justify-center font-semibold">Status</span>;
            },
            renderCell: (item) => {
                let status = item.status ? hashStatus[`${item.status}`]?.status : item.status
                let typeStatus = 'info' as any
                let className = ' text-blue-900'

                if (status === STATUS_RMA_CENTER_DEFAULT_SHIPPED) {
                    typeStatus = "success"
                    className = ' text-green-900'
                }
                if (status === STATUS_RMA_CENTER_DEFAULT_DRAFT) {
                    typeStatus = "warning"
                    className = ' text-yellow-600'
                }
                return (<div className="flex justify-center w-full">
                    <Alert className={`${className} w-[100px] text-center `} message={status} type={typeStatus} />
                </div>)
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: 'create_at',
            renderHeaderCell: () => {
                return <span className="font-semibold">Created</span>
            },
            renderCell: (item) => {
                return item.create_at
            },
        }),
        createTableColumn<iRmaPostViewPage>({
            columnId: "actions",
            renderHeaderCell: () => {
                return <span className="font-semibold">Actions</span>
            },
            renderCell: (user) => {
                return (
                    <div className="flex gap-1">
                        <Button onClick={() => { setPostIdSelected(() => user.id); setOpenViewPost(() => true) }} aria-label="Open" > View</Button >
                        <Button className="w-1/2" appearance="primary" onClick={() => { handlePrintRma(user.id) }} aria-label="Open" > Print</Button >
                    </div>
                );
            },
        }),
    ], [data])



    if (selectRMA !== -1)
        return (
            <LayoutPage title_layout="Print" childrenHeader={
                <Button onClick={() => setSelectRMA(() => -1)} appearance={'primary'} className="w-1/9 ">
                    Back
                </Button>
            } >
                <ViewerPdf idPostSelected={selectRMA} isAdmin />
            </LayoutPage>
        )

    return (
        <>
            <header className="flex w-full gap-2">
                <div className="w-full">
                    <Label htmlFor="search-input-admin-page" weight="semibold">Search</Label>
                    <Field>
                        <SearchBox
                            id="search-input-admin-page"
                            value={searchInput}
                            onChange={onChangeInput}
                            placeholder={placeholderMemo}
                            appearance="outline"
                        />
                    </Field>
                </div>
                <div className="w-[100px]">
                    <Label htmlFor="search-filter-admin-page" weight="semibold">Filter</Label>
                    <Select id="search-filter-admin-page" onChange={(event) => handleFilter(event.target.value)} >
                        <option value={'f_n'}>Name</option>
                        <option value={'f_e'}>Email</option>
                        <option value={'f_rc'}>RMA</option>
                    </Select>
                </div>
                <div className="w-1/3">
                    <Label htmlFor="search-select-admin-page" weight="semibold">Status</Label>
                    <Select disabled={selectFilter === 'f_rc'} value={selectStatus} onChange={(event) => handleStatus(event.target.value)} id="search-select-admin-page" >
                        <option value={-1}> ... </option>
                        {renderOptionStatus}
                    </Select>
                </div>
                <div className="pt-[20px]" >
                    <Button onClick={handleSearch} appearance={'primary'} className="w-1/6 h-[32px]">
                        Search
                    </Button>
                </div>
            </header>

            {(data.length === 0 && !loading) ?
                <div className="min-h-[50lvh] flex justify-center items-center pb-[50px]">
                    <span>No RMA requests found</span>
                </div>
                :
                <div className="overflow-x-hidden min-h-[50lvh]">
                    {loading ? <Spinner className=" min-h-[50lvh]" label="Loading ..." />
                        :
                        <>
                            <DataGrid
                                items={data}
                                className="overflow-x-auto"
                                selectionMode="single"
                                focusMode="composite"
                                style={{ minWidth: "550px" }}
                                columns={columns}
                                sortable
                            >
                                <DataGridHeader>
                                    <DataGridRow>
                                        {({ renderHeaderCell }) => (
                                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                        )}
                                    </DataGridRow>
                                </DataGridHeader>
                                <DataGridBody<iRmaPostViewPage>>
                                    {({ item, rowId }) => (
                                        <DataGridRow<iRmaPostViewPage>
                                            key={rowId}
                                            selectionCell={{ radioIndicator: { "aria-label": "Select row" } }}
                                        >
                                            {({ renderCell }) => (
                                                <DataGridCell>{renderCell(item)}</DataGridCell>
                                            )}
                                        </DataGridRow>
                                    )}
                                </DataGridBody>
                            </DataGrid>
                            <Pagination
                                showSizeChanger={false}
                                onChange={(current) => updatePagination(current)}
                                className="my-5 float-end"
                                defaultPageSize={limit}
                                current={skipPagination}
                                total={total}
                                pageSize={limit}
                            />
                        </>
                    }
                </div>
            }
            < ManagerView
                hashStatus={hashStatus}
                t={t}
                open={openViewPost}
                onClose={() => { setOpenViewPost(() => false); setPostIdSelected(-1); }}
                idPostSelected={postIdSelected}
            />
        </>
    )
}