import { useMemo, useRef, useState } from "react";
import { useOnMountUnsafe } from "../../../hook/useOnMountUnsafe";
import { Alert, Button, Form, Input, notification, Select } from "antd";
import { useModalConfirm } from "../../../context/toast.context";
import usePagination from "../../../hook/usePagination";
import { iDirections, iFormManager } from "../../../util/types";
import ManagerCreateSkeleton from "./manager-create-skeleton";
import Address from "../address/address";
import UploadPreview from "../upload-files/view/preview";
// import useFetch from "../../../hook/useFetch";
import useComponentUpload from "./../upload-files/upload/upload";
import useValidationSerialNumber from "./validation-serial-number";
import Service from "../../../service/sendRequest";
import { FiSave } from "react-icons/fi";
import { validateProductType } from "../../../util/util";
import { PRODUCT_TYPE_HMI_Y_ACCESSORY, PRODUCT_TYPE_INDUSTRIAL_PC, PRODUCT_TYPE_OTHER } from "../../../util/const";
// import { IoCloudUpload } from "react-icons/io5";
// import DestinationType from "../destination-type";
// import Destination from "../destination/destination";


const INIT_STATE_DESTINATION: iDirections = {
    id: -1, address_code: '', company_name: '', address_line_1: '', address_line_2: '', address_line_3: '', zip_code: '', city: '', province: '', country: '',
}
const INITIAL_STATE_FORM: iFormManager = {
    id: -1,
    id_rma: -1,
    id_status: -1,
    fault_description: '',
    id_ts_case: '',
    serial_number: '',
    product_type: '',
    id_rma_center: undefined,
    item_code: '',
    item_model: '',
    isProductSerialOld: false,
    destination: INIT_STATE_DESTINATION,
    address_code: '',
    create_at: '',
    customer_code: '',
    customer_case_id: '',
    item_revision: '',
    rma_status_description: '',
    update_at: '',
    attachments: []
}

type props = {
    t: (key: string) => string
    idPostSelected: number
    close: () => void
}

export default function ManagerCreate({ t, close, idPostSelected }: props) {
    const [loading, setLoading] = useState(false)
    const { funcModalConfig } = useModalConfirm()
    const [api, contextHolderNotification] = notification.useNotification()

    const refFocus = useRef<any>()

    const { data: serviceCenter } = usePagination(Service.getAllCenter, "id", 20, "select")

    const [form, setForm] = useState<iFormManager>(INITIAL_STATE_FORM)
    const refAbortController = useRef<AbortController>()
    const handleAbortController = () => {
        if (refAbortController.current) refAbortController.current.abort()
    }
    const initialSearch = useRef(false)

    useOnMountUnsafe(() => {
        const getData = async () => {
            initialSearch.current = true
            setLoading(() => true)
            try {
                refAbortController.current = new AbortController()
                const response = await Service.getRmaManagerId(idPostSelected, refAbortController.current.signal)
                if (response?.error) return
                const product_type = validateProductType({product_type:response?.product_type,
                     item_code:response.item_code,
                     item_model:response.item_model,
                     serial_number :response.serial_number
                })
                setForm(() => {
                    return {
                        ...response as iFormManager,
                        item_revision: response.item_revision || '',
                        product_type
                        // (response.serial_number.trim().length !== 0 || response.item_code.trim().length !== 0 || response.item_model.trim().length !== 0) ? 'PRODUCT_TYPE_HMI_Y_ACCESSORY' : 'PRODUCT_TYPE_INDUSTRIAL_PC'
                    }
                })
            } catch (error) {
                console.log('error: ', error)
            }
            setLoading(() => false)
        }
        if (idPostSelected && idPostSelected !== -1) {
            getData()
            return
        }

        // if (idPostSelected === -1 || !idPostSelected || initialSearch.current) return
        return () => handleAbortController()
    }, [idPostSelected])
    // const { data: form, setData: setForm, loading: loadingForm } = useFetch<iFormManager>(Service.getRmaManagerId, idPostSelected, INITIAL_STATE_FORM)

    const { files, renderButtonUpload, renderViewer } = useComponentUpload({ attachments: form.attachments, id: idPostSelected, t })

    const isRequiredSerialNumber = useMemo(() => form.product_type === PRODUCT_TYPE_HMI_Y_ACCESSORY, [form.id, form.product_type])
    const { serialValidation, serialNumber, setSerialNumber, setSerialValidation } = useValidationSerialNumber({ form, setForm, t, isRequired: isRequiredSerialNumber })


    const onChange = (event: any) => {
        const { value, name } = event.target
        setForm((prev) => { return { ...prev, [name]: value } })
    }

    const [validation, setValidation] = useState({ product_type: '', fault_description: '', id_rma_center: '', id_ts_case: '', address: '' })
    const validationForm = () => {
        let isError = false
        let validate = {
            product_type: '',
            fault_description: '',
            id_rma_center: '',
            address: '',
        }
        if (form.isProductSerialOld) isError = true
        if (!form.product_type) {
            validate = { ...validate, product_type: 'error' }
            isError = true
        }
        if (form.fault_description?.length === 0) {
            validate = { ...validate, fault_description: 'error' }
            isError = true
        }
        if (!form.id_rma_center) {
            validate = { ...validate, id_rma_center: 'error' }
            isError = true
        }
        if (isRequiredSerialNumber && (serialNumber.length === 0 || form.item_code?.length === 0)) {
            setSerialValidation(prev => { return { ...prev, status: 'error' } })
            isError = true
        }
        if (form.destination?.company_name === '') {
            validate = { ...validate, address: 'error' }
            isError = true
        }

        setValidation(validate as any)
        if (validate) refFocus.current.focus()
        return isError
    }

    const validationEditRef = useRef(false)
    const onFinish = async () => {
        if (validationForm() || validationEditRef.current) return
        validationEditRef.current = true
        const responseConfirm: boolean = await funcModalConfig(t('toast.message.confirmation'), t('page.manager.create.attention'), t('btn.confirmation.save'), t('message.cancel'))
        validationEditRef.current = false
        if (!responseConfirm) return
        setLoading(() => true)
        if (form.id === -1)
            return onCreateRmaPost()
        onUpdateRmaPost()
    }

    const handleResponse = (response: any) => {
        if (response?.error || response?.errors) {
            let errorMessages = "There's an error!"
            let typeApi: "success" | "info" | "warning" | "error" = 'error'
            let duration = 2000

            if (response?.errorMessages === "InternalExceptionError")
                errorMessages = t('page.manager.message.error')
            // if (response.errorMessages === "UserCustomerNotFoundException") {
            //     errorMessages = "User in the validation process, you cannot create an RMA post until it is validated"
            //     typeApi = 'warning'
            //     duration = 5000
            // }
            api[typeApi]({ message: `Notification `, description: errorMessages, placement: 'topRight', duration, })
            throw Error(errorMessages)
        }
        close()
    }

    const onCreateRmaPost = async () => {
        try {
            const formData = new FormData()
            formData.append('length', files.length + '')
            for (let i = 0; i < files.length; i++) {
                formData.append(`file_${i}`, files[i]);
            }
            formData.append("id", form.id + '')
            formData.append("id_rma", form.id_rma + '')
            formData.append("service_center", form.id_rma_center + '')
            formData.append("serial_number", serialNumber + '')
            formData.append("product_type", form.product_type + '')
            formData.append("item_code", form.item_code + '')
            formData.append("item_model", form.item_model + '')
            formData.append("technical_number_model", form.id_ts_case + '')
            formData.append("customer_case_id", form.customer_case_id + '')
            formData.append("customer_code", form.customer_code + '')
            formData.append("fault_description", form.fault_description.replace(/[\n\r\t\f\v]/g, '') + ' ')
            formData.append("destination", JSON.stringify(form.destination))

            await Service.postRmaPost(formData)
                .then((response: any) => {
                    handleResponse(response)
                    setLoading(() => false)
                })
        } catch (error) {
            setLoading(() => false)
            console.log('error: ', error)
        }
    }

    const onUpdateRmaPost = async () => {
        try {
            const data = {
                id: form.id,
                id_rma: form.id_rma,
                service_center: form.id_rma_center,
                serial_number: serialNumber,
                product_type: form.product_type,
                item_code: form.item_code,
                item_model: form.item_model,
                technical_number_model: form.id_ts_case,
                customer_code: form.customer_code,
                fault_description: form.fault_description.replace(/[\n\r\t\f\v]/g, ' '),
                customer_case_id: form.customer_case_id,
                destination: { ...form.destination },
            }
            await Service.putRmaPost(data)
                .then((response: any) => {
                    handleResponse(response)
                    setLoading(() => false)
                })
        } catch (error) {
            console.log('error: ', error)
            setLoading(() => false)
        }
    }

    // if (loadingServiceCenter || loadingForm) return <ManagerCreateSkeleton />
    return (
        <>
            {contextHolderNotification}
            {/* <p className={`py-2 text-gray-400 ${idPostSelected !== -1 && "hidden"}`}>
                {t('page.manager.create.attention')}
            </p> */}
            <Form
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
                requiredMark
                autoComplete="off"
                style={{ maxWidth: 1200 }}
                className={` ${loading && 'cursor-wait animate-pulse'} my-5 grid grid-cols-4 gap-5 sm:gap-2 px-2 sm:px-0 h-full w-full font-semibold`}
            >
                {loading
                    ?
                    <ManagerCreateSkeleton />
                    :
                    <>
                        <Form.Item
                            className="col-span-4  sm:col-span-1 w-full"
                            label={t('page.manager.view.product_type')}
                            validateStatus={validation.product_type as any}  // validateStatus="error"
                            required
                        >
                            <Select className="font-normal"
                                ref={refFocus}
                                value={form.product_type}
                                // defaultValue={form.serial_number.trim().length === 0 ? 'PRODUCT_TYPE_INDUSTRIAL_PC' : 'PRODUCT_TYPE_HMI_Y_ACCESSORY'}
                                onChange={(value) => setForm(prev => { return { ...prev, product_type: value } })}
                            >
                                <Select.Option value={PRODUCT_TYPE_INDUSTRIAL_PC}>Industrial PC</Select.Option>
                                <Select.Option value={PRODUCT_TYPE_HMI_Y_ACCESSORY}>HMI & Accessory</Select.Option>
                                <Select.Option value={PRODUCT_TYPE_OTHER}>Other</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className="col-span-4  sm:col-span-3 h-28"
                            hasFeedback
                            label={t('page.manager.view.serial_number')}
                            validateStatus={serialValidation.status as any}
                            required={isRequiredSerialNumber}
                        >
                            <Input
                                required={isRequiredSerialNumber}
                                className="font-normal"
                                value={serialNumber}
                                name="serial_number"
                                onChange={(event: any) => {
                                    const { value } = event.target
                                    setSerialNumber(() => value)
                                }}
                            />
                            {serialValidation.message.length !== 0 &&
                                <Alert className={`animate-zoom-in animate-duration-faster mt-1 py-1 ${serialValidation.status === 'success' && 'text-green-600'} ${serialValidation.status === 'error' && 'text-red-600'} `}
                                    message={serialValidation.message}
                                    type={serialValidation.status as any}
                                />
                            }
                        </Form.Item>

                        <Form.Item
                            className={`${serialValidation.status === "validating" && "animate-pulse "} col-span-4 sm:col-span-2 lg:col-span-1 w-full`}
                            label={t('page.manager.view.item_code')}
                            validateStatus={serialValidation.status as any}
                            required={isRequiredSerialNumber}
                        >
                            <Input
                                required={isRequiredSerialNumber}
                                value={form.item_code} disabled
                                className={`${serialValidation.status === "validating" && "bg-gray-700"} font-normal text-black disabled:text-gray-700`} />
                        </Form.Item>

                        <Form.Item
                            required={isRequiredSerialNumber}
                            className={`${serialValidation.status === "validating" && "animate-pulse "} col-span-4 sm:col-span-2 lg:col-span-1 w-full`}
                            label={t('page.manager.view.item_model')}
                            validateStatus={serialValidation.status as any}
                        >
                            <Input
                                required={isRequiredSerialNumber}
                                value={form.item_model} disabled
                                className={`${serialValidation.status === "validating" && "bg-gray-700"} font-normal text-black disabled:text-gray-700`} />
                        </Form.Item>

                        <Form.Item
                            className="col-span-4 sm:col-span-2 lg:col-span-1 w-full"
                            label={t('page.manager.view.id_ts_case')}
                        >
                            <Input
                                className="font-normal"
                                onChange={onChange} value={form.id_ts_case} name="id_ts_case"
                                maxLength={10} />
                        </Form.Item>

                        <Form.Item
                            className="col-span-4 sm:col-span-2 lg:col-span-1 w-full"
                            label={t('page.manager.view.customer_case_id')}
                        >
                            <Input
                                className="font-normal"
                                onChange={onChange} value={form.customer_case_id} name="customer_case_id"
                                maxLength={10} />
                        </Form.Item>

                        <Form.Item
                            className="col-span-4 sm:col-span-2"
                            label={t('page.manager.view.fault_description')}
                            required
                            validateStatus={validation.fault_description as any}
                        >
                            <Input.TextArea // status={messageError.fault_description as any} 
                                value={form.fault_description} onChange={onChange} name="fault_description"
                                className="font-normal h-44 max-h-[176px] min-h-[176px]" showCount maxLength={250} />
                        </Form.Item>
                        <div className="col-span-4 sm:col-span-2 flex flex-col w-full">
                            <Form.Item
                                className="mb-2"
                                label={t('page.manager.view.service_center')}
                                required
                                validateStatus={validation.id_rma_center as any}
                            >
                                <Select
                                    className="font-normal w-full"
                                    value={form.id_rma_center}
                                    onChange={(value) => { setForm(prev => { return { ...prev, id_rma_center: value } }) }}
                                >
                                    {serviceCenter.map((service: any) => (
                                        <Select.Option key={service.id} value={service.id}>{service.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <div>
                                {idPostSelected === -1 ?
                                    renderButtonUpload
                                    :
                                    <UploadPreview initialAttachments={form.attachments} ID_RMA={idPostSelected} />
                                }
                            </div>
                        </div>
                    </>
                }
                <hr className="col-span-4" />
                <div className={`${loading && 'animate-pulse '} col-span-4 relative ${validation.address === "error" && "p-1 border-[1px] border-[#ff4d4f] rounded-md"}`}>
                    <Address
                        t={t}
                        type={idPostSelected === -1 ? 'CREATE' : 'EDIT'}
                        // loading={loadingForm}
                        verified={form.verified_destination}
                        defaultDestination={form.destination || INIT_STATE_DESTINATION}
                        setDestinationParent={(destination) => setForm((prev) => { return { ...prev, destination } })}
                    />

                    {/* <Destination
                        _verified={form.verified_destination}
                        t={t}
                        defaultDestination={form.destination || INIT_STATE_DESTINATION}
                        setDestinationParent={(destination) => setForm((prev) => { return { ...prev, destination } })}
                    /> */}
                    {/* <DestinationType
                        isStatusModeCreate={mode === "create"}
                        t={t}
                        defaultDestination={form.destination}
                        setDestinationParent={(destination) => setForm((prev) => { return { ...prev, destination } })}
                    /> */}
                </div>
                <hr className="col-span-4" />
                <Form.Item className="col-span-4 mx-0 sm:mx-2" >
                    <Button
                        className="bg-customColor-exor_grey rounded-lg w-full md:w-1/3" type="primary"
                        htmlType="submit"
                    >
                        <FiSave />
                        {t('btn.confirmation.save')}</Button>
                </Form.Item>
                {renderViewer}
            </Form>
        </>
    )
}
