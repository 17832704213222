import { CompoundButton, Spinner, Title1 } from "@fluentui/react-components"
import LayoutPage from "../../component/ui/layout-page-component"
import { MdMessage } from "react-icons/md";
import { RiFileUserLine } from "react-icons/ri";
import { GrDocumentConfig } from "react-icons/gr";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AdminPage from "./admin-page";
import { useLocalConfigUser } from "../../context/local-config-user.context";
import { useEffect } from "react";

export default function LayoutAdmin({ children }: { children: React.ReactNode }) {
    const { pathname } = useLocation()

    const { loading, role, status } = useLocalConfigUser()
    const navigate = useNavigate()

    useEffect(() => {
        if (status === 'ok' && role === 'USER') {
            navigate('/')
            return
        }
    }, [status, role, loading])

    if (loading) return <Spinner size="extra-large" />

    if (pathname === '/auth/admin') return (<AdminPage pathname={pathname} />)

    return (
        <>
            <div className="print:hidden print:p-0 print:m-0 bg-black text-white py-5  px-10 flex items-center justify-between">
                <Title1 > Administration Page</Title1>
                <ul className="flex gap-2 md:gap-4">
                    <li>
                        <Link to={"/auth/admin/message"}>
                            <div className=" hidden md:block">
                                <CompoundButton
                                    size="small"
                                    appearance={'/auth/admin/message' === pathname ? "primary" : 'secondary'}
                                    icon={<MdMessage size={25} />}
                                >
                                    Web Messages
                                </CompoundButton>
                            </div>
                            <MdMessage className={` ${'/auth/admin/message' === pathname && 'text-blue-500'} block md:hidden`} size={20} />
                        </Link>
                    </li>
                    <li>
                        <Link to={"/auth/admin/users"}>
                            <div className=" hidden md:block">
                                <CompoundButton
                                    size="small"
                                    appearance={'/auth/admin/users' === pathname ? "primary" : 'secondary'}
                                    icon={<RiFileUserLine size={25} />}
                                >
                                    User Management
                                </CompoundButton>
                            </div>
                            <RiFileUserLine className={` ${'/auth/admin/users' === pathname && 'text-blue-500'} block md:hidden`} size={20} />
                        </Link>
                    </li>
                    <li>
                        <Link to={"/auth/admin/rma"}>
                            <div className=" hidden md:block">
                                <CompoundButton
                                    size="small"
                                    appearance={'/auth/admin/rma' === pathname ? "primary" : 'secondary'}
                                    icon={<GrDocumentConfig size={25} />}
                                >
                                    RMA Management
                                </CompoundButton>
                            </div>
                            <GrDocumentConfig className={` ${'/auth/admin/rma' === pathname && 'text-blue-500'} block md:hidden`} size={20} />
                        </Link>
                    </li>
                </ul>
            </div>
            <LayoutPage
                className="print:p-0 print:m-0  flex flex-col gap-4 px-4 sm:px-0 md:container py-5 mx-auto"
                disableDefaultClassName
                title={'RMA - Admin'}
            >
                {children}
            </LayoutPage>
        </>
    )
}