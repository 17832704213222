import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import LayoutPage from "../../component/ui/layout-page-component";
import { Button } from "antd";
import PrivacyNotice from "../../component/ui/privacy-notice";
import { login } from "../../service/keycloak.service";
import { Link } from "react-router-dom";
import background_home_2 from "../../assets/image/background_home_02.jpg"
import logo_exor from "../../assets/image/LOGO_EXOR.png"
import logo_on3 from "../../assets/image/LOGO_ON3.png"
import { GrDocument, GrDocumentConfig } from "react-icons/gr";


export default function HomePage() {
    const { t } = useTranslation()
    const { keycloak: { authenticated } } = useKeycloak()
    return (
        <LayoutPage disableDefaultClassName className="overflow-x-hidden text-gray-900 p-0" title={t("page.title.home")}>
            <section className="relative text-white py-20 md:h-[550px] select-none">
                <div
                    className="absolute inset-0 z-10"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url(${background_home_2})`,
                        // backgroundImage: `url(${background_home})`,
                        backgroundSize: 'cover',
                        // backgroundPosition: 'center bottom 65%',
                        backgroundRepeat: 'no-repeat',
                    }}
                ></div>
                <div className="pt-9 sm:pt-32 z-30 relative container mx-auto text-center flex flex-col justify-center items-center">
                    <h1 className="mb-4 text-4xl font-bold leading-none tracking-tight md:text-5xl lg:text-6xl ">{t("page.home.description")}</h1>
                    {/* <h1 className="text-center w-full md:w-[90%] xl:w-1/2 animate-slide-in-left animate-duration-slow text-4xl md:text-5xl font-bold mb-4">{t("page.home.description")}</h1> */}
                    <p className="text-xl mb-3">{t("page.home.information")}</p>
                    <p className="text-xl mb-3">{t("page.home.information_1")}</p>
                    {!authenticated ?
                        <button onClick={() => login()} className="md:hidden bg-white font-bold text-customColor-exor_blue py-3 px-6 rounded-xl hover:bg-gray-100 transition duration-300" >{t('nav.path.login')}</button>
                        :
                        <Link to={'/auth/manager?opt=1'}>
                            <Button
                                className="w-full sm:w-auto p-7 text-lg hover:shadow-xl"
                                // onClick={() => handleCreatePostRMA()}
                                type="primary" >
                                <GrDocumentConfig />
                                {t('page.manager.btn.create_post')}
                            </Button>
                        </Link>
                    }
                </div>
            </section>

            {/* <section className="py-16 md:py-20 select-none">
                <div className="container mx-2 md:mx-auto">
                    <div className="grid grid-cols-2 justify-center items-center gap-8">
                        <div className="bg-gray-100 text-customColor-exor_grey p-6 rounded-lg text-center flex justify-center items-center gap-4 shadow-sm">
                            <FaTools className="text-4xl mb-2" />
                            <h3 className="text-sm md:text-xl font-semibold mb-2">{t('page.home.repair')}</h3>
                        </div>
                        <div className="bg-gray-100 text-customColor-exor_grey p-6 rounded-lg text-center flex justify-center items-center gap-4 shadow-sm">
                            <FaClock className="text-4xl mb-2" />
                            <h3 className="text-sm md:text-xl font-semibold mb-2">{t('page.home.quick_repair')}</h3>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="relative bg-customColor-exor_grey py-10 sm:py-20 h-auto .sm:h-[40lvh] select-none">
                <div className="grid justify-center items-center grid-cols-2 max-w-7xl">
                    <div className="grid justify-center items-center col-span-2  text-white sm:px-20 py-6 rounded-lg shadow-sm grid-cols-2 ">
                        <div className="container mx-2 sm:mx-auto col-span-2 text-left flex .justify-center items-center gap-4 pb-10 ">
                            <GrDocument className="text-4xl mb-2" />
                            {/* <h3 className="text-sm md:text-xl font-semibold mb-2">RMA procedure</h3> */}
                            <p className="text-sm md:text-xl font-semibold mb-2">Here you will find explanation for RMA procedure</p>
                        </div>
                        <div className="relative bg-[#cbcbcb] text-customColor-exor_grey py-8 px-5 m-2 flex gap-1 items-center rounded-md col-span-2 md:col-span-1" >
                            {/* <GrDocument /> */}
                            <span className="pr-2 font-semibold text-sm sm:text-lg">English</span>
                            <p className="hidden xl:block pt-[1px]">RMA Procedure for repair service</p>
                            <Link className="bg-customColor-exor_blue hover:bg-customColor-exor_blue/90 text-white rounded-md p-3 absolute right-5" target="_blank" to={'https://rmaexorintstorage.blob.core.windows.net/procedure/DOCUMENTATION-RMA-english.pdf'}>See the document</Link>
                        </div>
                        <div className="relative bg-[#cbcbcb] text-customColor-exor_grey py-8 px-5 m-2 flex gap-1 items-center rounded-md col-span-2 md:col-span-1" >
                            {/* <GrDocument /> */}
                            <span className=" pr-2 font-semibold text-sm sm:text-lg">Italiano</span>
                            <p className="hidden xl:block pt-[1px]">Procedura RMA per servizio riparazioni</p>
                            <Link className="bg-customColor-exor_blue hover:bg-customColor-exor_blue/90 text-white rounded-md p-3 absolute right-5" target="_blank" to={'https://rmaexorintstorage.blob.core.windows.net/procedure/DOCUMENTATION-RMA-italian.pdf'}>Vedere il documento</Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="relative bg-gray-100 py-10">
                <div
                    className="absolute inset-0 z-10"
                    style={{
                        backgroundImage: `url(${background_home})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center bottom 65%',
                    }}
                ></div>
                <div
                    className="z-20 absolute inset-0 bg-black opacity-30 md:opacity-50"
                    aria-hidden="true"
                ></div>
                <div className="z-30 relative">
                    <div className="py-14 container mx-2 sm:mx-auto">
                        <h2 className="text-2xl font-bold mb-4 text-gray-100">Contact Your Local Support</h2>
                        <div className="space-y-4">
                            {faqItems.map((item, index) => (
                                <div key={index} className="w-[95%] bg-white p-4 rounded-md shadow-md">
                                    <h3 className="text-xl font-semibold mb-2">{item.question}</h3>
                                    <p>{item.answer}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section> */}

            <section className=" bg-[#cbcbcb] text-black pt-16 sm:px-20 flex flex-col justify-center items-center  pb-5 h-auto sm:h-[40lvh] select-none">
                <div className="container mx-auto px-4 flex flex-col sm:flex-row justify-center items-center ">
                    <img className="h-24 object-contain" src={logo_exor} alt="logo_exor" />
                    <img className="h-28 object-contain" src={logo_on3} alt="logo_on3" />
                </div>
                <div className="container  text-left pt-10 pb-5">
                    <p className="text-sm sm:text-lg ml-2">
                        {t('page.home.note')}
                    </p>
                </div>
            </section>
            <PrivacyNotice t={t} />
        </LayoutPage>
    )
}