"use client"
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { logout, login } from "../../../service/keycloak.service";
import { useOnMountUnsafe } from "../../../hook/useOnMountUnsafe";
import { Button, Drawer, DrawerProps, Dropdown, MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import useClickOut from "../../../hook/useClickOut";
import LanguageSelector from "./language-selector";
import useRenderListPath from "./useRenderListPath";
import NotificationMessage from "./notification-message";
import { useLocalConfigUser } from "../../../context/local-config-user.context";
import useValidationPath from "../../../hook/useValidationPath";

// import logo_business from "../../../assets/image/EXOR_LOGO_FOOTER.png"
// import logo_business from "../../../assets/image/logo.png"
import logo_business from "../../../assets/image/EXOR_LOGO_WEB.png"
import { BiSolidLogOut } from "react-icons/bi";
import { RiLoginBoxLine } from "react-icons/ri";
import { MdAdminPanelSettings } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { Avatar } from "@fluentui/react-components";
import { Spinner } from "@fluentui/react-components";



const INITIAL_STATE_INFO_USER = {
    sub: '',
    email_verified: '',
    name: '',
    preferred_username: '',
    given_name: '',
    family_name: '',
    email: '',
}

export default function Navbar() {
    const { keycloak: { authenticated, loadUserInfo } } = useKeycloak()

    const { ref, isOpen: isOpenMenu, setIsOpen: setIsOpenMenu } = useClickOut()
    const { t, i18n: { language } } = useTranslation()
    const { pathname } = useLocation()

    const { isPathValidation } = useValidationPath("ticket")

    const { business, loading, role } = useLocalConfigUser()
    // * Menu
    const [open, setOpen] = useState(false);
    const [placement] = useState<DrawerProps['placement']>('left');

    const showDrawer = () => setOpen(true)

    const onClose = () => setOpen(false)

    const isAuthenticatedAndPathAuth = useMemo(() => authenticated && pathname === "/ticket", [authenticated, pathname])

    const { renderList } = useRenderListPath({ pathname, setIsOpenMenu, t, authenticated })

    const [informationUser, setInformationUser] = useState(INITIAL_STATE_INFO_USER)

    useOnMountUnsafe(() => {
        if (!authenticated) return
        loadUserInfo().then((info: any) => {
            setInformationUser(info)
        })
    })

    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
            if (currentScrollTop <= 10) {
                setIsVisible(true)
            } else if (currentScrollTop > lastScrollTop) {
                setIsVisible(false)
            } else {
                setIsVisible(true)
            }
            setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [lastScrollTop])

    const menuProps: MenuProps['items'] = useMemo(() => [
        {
            label: (
                <div className="cursor-auto flex gap-2 items-center text-gray-600 font-semibold">
                    <MdEmail /><span >{informationUser.email}</span>
                </div>
            ),
            key: '0',
        },
        {
            label: (
                <Link className="hover:opacity-70 flex gap-2 items-center font-semibold" to={'/auth/dashboard'}>
                    <RiDashboardFill /><span >{t('nav.path.dashboard')}</span>
                </Link>
            ),
            key: '1',
            className: "p-0 m-0 bg-blue-50",
        },
        {
            label: (
                role !== 'ADMIN' ?
                    <></>
                    :
                    <Link className="hover:opacity-70 flex gap-2 items-center font-semibold" to={'/auth/admin'}>
                        <MdAdminPanelSettings />
                        Admin
                    </Link>
            ),
            key: '2',
            className: ` p-0 m-0 ${role === 'ADMIN' && " bg-blue-100"} `,
        },
        {
            label: (
                <Button type="primary" danger onClick={() => logout()} className="w-full px-0 mx-0 rounded-md">{t("nav.path.sign_out")}</Button>
            ),
            key: '3',
        },
        // { type: 'divider', },
    ], [informationUser, language, role]);

    const renderUserMdInformation =
        useMemo(() => {
            if (isPathValidation)
                return <></>

            if (!authenticated)
                return (
                    <Button onClick={() => login()} type="primary" className="hidden md:block px-5 rounded-3xl mx-2 hover:animate-pulse font-semibold">
                        {t("nav.path.login")}
                    </Button>
                )
            const businessInformation = `${business}`.trim().length > 19 ? `${business?.slice(0, 19)}...` : business?.trim()
            return (
                <>
                    {informationUser.name.length !== 0
                        ?
                        <Dropdown className="hidden md:block p-0 ml-2 pr-5" trigger={['click']} menu={{ items: menuProps }} >
                            <div className="h-full  cursor-pointer hover:opacity-80 flex ">
                                {/* <span className="rounded-full h-5 w-5  bg-gray-300 p-5 text-white">{informationUser.name[0]}</span> */}
                                <div className="text-sm flex flex-row">
                                    {/* <Avatar size={"large"}>{informationUser.name[0]}</Avatar> */}
                                    <Avatar size={40} color="colorful" name={`${informationUser.name} ${informationUser.family_name}`} />
                                    <div className="flex flex-col">
                                        <span className="pl-2 ">{informationUser.name}</span>
                                        {(!loading && business) ?
                                            <span className="pl-2 font-semibold">{businessInformation}</span>
                                            :
                                            <span className="pl-2 font-semibold">{informationUser.email}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Dropdown>
                        :
                        <Spinner />
                    }
                </>
            )
        }, [authenticated, informationUser, t, business, loading])

    return (
        <>
            <header
                // className="fixed z-50 top-0 left-0 right-0 border-b-2 border-gray-300 print:hidden flex shadow-md sm:px-5 py-4 sm:py-5 h-14 bg-white border-b-1 .border-gray-400"
                className={`print:hidden print:p-0 print:m-0 print:h-0 h-[60px] border-b-2 border-gray-300 flex fixed shadow-md bg-[#f0f0f2] .bg-white z-50  top-0 left-0  w-full transition-transform duration-300 ease-in-out ${isVisible ? 'block' : 'transform -translate-y-full '}`}
            >
                {/* <Link className="flex justify-center items-center sm:mr-10 relative" to={!isPathValidation ? "/" : "/ticket"}>
                    <img className="pl-4 sm:pl-2 .object-contain h-auto w-[100px]" alt="logo_business" src={logo_business} />
                    <img className="pl-4 sm:pl-2 h-auto w-[100px] object-contain" alt="logo_business" src={logo_business} />
                    <h1 className="hidden">Exor International S.p.A.</h1>
                    </Link> */}


                <Link to={!isPathValidation ? "/" : "/ticket"} className="header__logo">
                    <img className="logo" alt="logo_business" src={logo_business} />
                </Link>
                <nav className={`pl-[250px] relative w-full flex ${isAuthenticatedAndPathAuth ? "justify-end" : "justify-between"}  items-center`}>
                    {(!isAuthenticatedAndPathAuth) && (
                        <div ref={ref} className={` z-50 rounded-md backdrop-blur-lg md:backdrop-blur-none w-full md:flex gap-2 md:flex-row md:justify-start md:items-center md:gap-5 
                            ${isOpenMenu ? "absolute md:relative top-10 sm:top-12 w-screen sm:w-full right-0 md:top-0 shadow-md md:shadow-none" : "hidden "}
                            `}>
                            {!isPathValidation && renderList}
                        </div>
                    )}
                    <div className={`w-full flex gap-2 items-center justify-end .xl:text-[15px]`}>
                        <LanguageSelector />
                        {renderUserMdInformation}
                        <div
                            onClick={showDrawer}
                            className={`${isPathValidation && 'hidden'} md:hidden group flex h-10 w-10 mx-1 cursor-pointer items-center justify-center .rounded-3xl`}>
                            <div className="space-y-2">
                                <span className="block h-1 w-10 origin-center rounded-full bg-customColor-exor_grey transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45 group-hover:bg-customColor-exor_blue"></span>
                                <span className="block h-1 w-8 origin-center rounded-full bg-customColor-exor_blue transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
                            </div>
                        </div>
                    </div>
                </nav>
                <Drawer
                    rootClassName="block md:hidden"
                    className="bg-white"
                    placement={placement}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key={placement}
                    onClick={onClose}
                    style={{ textDecoration: "none" }}
                >
                    {informationUser.name.length !== 0
                        ?
                        <>
                            <div className="py-5 flex border-2 bg-slate-50 border-blue-50 rounded-lg p-2">
                                <Avatar size={48} color="blue" name={`${informationUser.name} ${informationUser.family_name}`} />
                                <div className="flex flex-col">
                                    <span className="pl-2 text-lg">{informationUser.name}</span>
                                    <span className="pl-2 font-semibold">{informationUser.email}</span>
                                </div>
                            </div>
                            <Link className={`md:px-1 md:hover:text-gray-500 md:font-bold hover:font-bold text-white md:text-slate-700`} to={'/auth/dashboard'}>
                                <div className=" bg-customColor-exor_blue hover:bg-customColor-exor_blue/90 font-medium text-base rounded-md p-5 my-1 md:p-0 md:bg-white ">
                                    {t('nav.path.dashboard')}
                                </div>
                            </Link>
                        </>
                        :
                        <></>
                    }
                    {renderList}
                    {role === "ADMIN" &&
                        (
                            <Link to={'/auth/admin'} className="w-full flex gap-2 justify-start items-center text-white text-left hover:bg-customColor-exor_blue/50 hover:text-customColor-exor_grey bg-customColor-exor_blue/90 font-medium text-base rounded-md p-5 my-1 " >
                                <MdAdminPanelSettings size={25} /> Admin
                            </Link>
                        )
                    }
                    {!authenticated ?
                        <button onClick={() => login()} className="w-full flex gap-2 justify-start items-center text-white text-left hover:bg-customColor-exor_blue/90 bg-customColor-exor_blue font-medium text-base rounded-md p-5 my-1 "><RiLoginBoxLine size={25} /> <span>{t("nav.path.login")}</span></button>
                        :
                        <button onClick={() => logout()} className="w-full flex gap-2 justify-start items-center text-white text-left hover:bg-customColor-exor_blue/90 bg-customColor-exor_blue font-medium text-base rounded-md p-5 my-1 "><BiSolidLogOut size={25} /> <span>{t("nav.path.sign_out")}</span></button>
                    }
                </Drawer>
            </header >
            {!isPathValidation && <NotificationMessage lang={language} />}
        </>
    )
}