import { useOnMountUnsafe } from '../../../hook/useOnMountUnsafe'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { iGeneratePrint } from '../../../util/types'
import logo_business from '../../../assets/image/EXOR_LOGO_WEB.png'
import './Print-html.css'

export default function PrintHTML ({
  // attachments,
  destination,
  rma,
  // rma_center,
  user
}: iGeneratePrint) {
  const { t } = useTranslation()

  useOnMountUnsafe(() => {
    setTimeout(() => {
      handlePrint()
    }, 1000)
  })

  const handlePrint = () => {
    window.print()
  }

  const Information = () => (
    <>
      <div className='h-[13.5cm] relative grid grid-cols-2 pb-[50px]'>
        <div className='col-span-2 flex justify-between items-center'>
          <img
            className='pt-0 pb-5 logo'
            alt='logo_business'
            src={logo_business}
          />
          <div className='col-span-5 pt-4 pb-5 text-right'>{rma.create_at}</div>
        </div>

        <div className='col-span-2 grid grid-cols-4 items-start'>
          <h2 className='col-span-full font-extrabold text-[25px] pb-5 pl-4'>
            REPAIR INFORMATION
          </h2>

          <div className='col-span-4 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Customer Name: </span>
            <span className='text-right'>{user.name}</span>
          </div>
          <div className='col-span-4 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Customer Mail: </span>
            <span className='text-right'>{user.mail}</span>
          </div>
          <div className='col-span-2 flex gap-4 items-center'>
            <span className='font-bold pl-5 text-left'>Company:</span>
            <span className='text-right'>{user.company}</span>
          </div>
          <div className='col-span-2 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Customer Phone:</span>
            <span className='text-right'>{user.phone}</span>
          </div>

          <div className='col-span-2 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Customer Case ID:</span>
            <span className='text-right'>{rma.customer_case_id}</span>
          </div>
          <div className='col-span-2 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Exor Case ID: </span>
            <span className='text-right'>{rma.id_ts_case}</span>
          </div>

          <div className='col-span-2 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Model:</span>{' '}
            <span className='text-right'>{rma.item_model}</span>
          </div>
          <div className='col-span-2 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Serial Number: </span>
            <span className='text-right'>{rma.serial_number}</span>
          </div>
          <div className='col-span-2 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>Item Code: </span>
            <span className='text-right'>{rma.item_code}</span>
          </div>
          <div className='col-span-2 flex gap-2 items-center'>
            <span className='font-bold pl-5 text-left'>RMA Code: </span>
            <span className='text-right'>RMA_{rma.id_rma}</span>
          </div>
        </div>
        <div className='col-span-2 grid grid-cols-2 items-start'>
          <h2 className='col-span-2 font-extrabold text-[20px] pb-0 pl-5 pt-3'>
            ISSUE REPORTED
          </h2>
          <p className='col-span-2 w-full p-2 h-[150px] border-2 border-gray-200'>
            {rma.fault_description}
          </p>
        </div>

        <div className='absolute bottom-5 right-0 flex gap-3 justify-between items-center '>
          <span className='font-medium'>WARRANTY:</span>
          <div className='border-2 border-black h-[20px] w-[60px]'></div>
        </div>
        <div className='absolute bottom-5 right-[170px] flex gap-3 justify-between items-center '>
          <span className='font-medium'>FAULT CODE:</span>
          <div className='border-2 border-black h-[20px] w-[60px]'></div>
        </div>
      </div>
      <div className='h-[13.5cm] border-dashed border-t-2 border-slate-500 flex flex-col py-4'>
        <div className='grid grid-cols-6'>
          <img
            className='col-span-1 pt-4 pb-5 logo'
            alt='logo_business'
            src={logo_business}
          />
          <div className='col-span-5 pt-4 pb-5 text-right'>{rma.create_at}</div>

          <div className='col-span-3 grid grid-cols-2 items-start'>
            <h2 className='col-span-2 font-extrabold text-[20px] pb-4 pl-5'>
              INVOICE TO
            </h2>
            <span className='font-bold pl-5 text-left'>Company:</span>
            <span>Exor International S.p.A</span>
            <span className='font-bold pl-5 text-left'>Street:</span>
            <span>VIA MONTE FIORINO, 13/A</span>
            <span className='font-bold pl-5 text-left'>City:</span>
            <span>SAN GIOVANNI LUPATOTO</span>
            <span className='font-bold pl-5 text-left'>Region:</span>
            <span>VR</span>
            <span className='font-bold pl-5 text-left'> Zip Code:</span>
            <span>37057</span>
            <span className='font-bold pl-5 text-left'>State:</span>
            <span>ITALY</span>
          </div>

          <div className='col-span-3 grid grid-cols-2 items-start'>
            <h2 className='col-span-2 font-extrabold text-[20px] pb-4 pl-5'>
              SHIP TO
            </h2>
            <span className='font-bold pl-5 text-left'>Company:</span>
            <span>Exor International S.p.A</span>
            <span className='font-bold pl-5 text-left'>Street:</span>
            <span>VIA MONTE FIORINO, 13/A</span>
            <span className='font-bold pl-5 text-left'>City:</span>
            <span>SAN GIOVANNI LUPATOTO</span>
            <span className='font-bold pl-5 text-left'>Region:</span>
            <span>VR</span>
            <span className='font-bold pl-5 text-left'> Zip Code:</span>
            <span>37057</span>
            <span className='font-bold pl-5 text-left'>State:</span>
            <span>ITALY</span>
            <span className='font-bold pl-5 text-left'>Phone Number:</span>
            <span>0039 045 8750404</span>
          </div>
          <div className='col-span-4 grid grid-cols-4 items-start pl-5 '>
            <h2 className='col-span-full font-extrabold text-[20px] pb-4 pt-5'>
              RETURN DESTINATION
            </h2>
            {/* <span className='font-bold pl-5 text-left'>Code:</span>
            <span> RMA_{rma.id_rma}</span> */}
            <span className='col-span-1 font-bold pl-5. text-left'>
              Company:
            </span>
            <span className='col-span-3 truncate'>
              {destination.company_name}
            </span>
            <span className='col-span-1 font-bold pl-5. text-left'>City:</span>
            <span className='col-span-3'>{destination.city}</span>
            <span className='col-span-1 font-bold pl-5. text-left'>
              Address 1:
            </span>
            <span className='col-span-3'>{destination.address_line_1}</span>
            <span className='col-span-1 font-bold pl-5. text-left'>
              Address 2:
            </span>
            <span className='col-span-3'>{destination.address_line_2}</span>
            <span className='col-span-1 font-bold pl-5. text-left'>
              Address 3:
            </span>
            <span className='col-span-3'>{destination.address_line_3}</span>
            <span className='col-span-1 font-bold pl-5. text-left'>
              Zip Code:
            </span>
            <span className='col-span-3'>{destination.zip_code}</span>
            <span className='col-span-1 font-bold pl-5. text-left'>
              {' '}
              Province:
            </span>
            <span className='col-span-3'>{destination.province}</span>
            <span className='col-span-1 font-bold pl-5. text-left'>
              {' '}
              Country:
            </span>
            <span className='col-span-3'>{destination.country}</span>
          </div>
          <div className='col-span-2 grid grid-cols-1 items-center'>
            <h2 className='col-span-1 font-extrabold text-[20px] pb-0  pt-5'>
              RMA INFORMATION
            </h2>
            <span className=' font-bold  text-left'>Code:</span>
            <span className='pl-2'>RMA_{rma.id_rma}</span>
            <span className=' font-bold  text-left'>Serial Number:</span>
            <span className='pl-2'>{rma.serial_number}</span>
            <span className=' font-bold  text-left'>Item Code:</span>
            <span className='pl-2'>{rma.item_code}</span>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <div className=''>
      <Button
        className='print:hidden my-5 w-full disabled:hover:bg-none uppercase bg-customColor-exor_blue hover:bg-customColor-exor_blue/80'
        type='primary'
        onClick={() => handlePrint()}
      >
        {t('message.tooltip.print')}
      </Button>
      <div className='hidden print:block'>
        <div className='print-container flex flex-col'>{<Information />}</div>
      </div>
      <div className='select-none print:hidden bg-[#323639] rounded-t-lg px-auto py-10 flex justify-center'>
        <div className='preview-print-container '>{<Information />}</div>
      </div>
    </div>
  )
}
