import { PRODUCT_TYPE_HMI_Y_ACCESSORY, PRODUCT_TYPE_INDUSTRIAL_PC, PRODUCT_TYPE_OTHER } from "./const";


export function transformBase64ImageToBlob(base64Image: string, contentType: string) {
    const byteCharacters = atob(base64Image);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: contentType });

    return blob
}

// YYYY/MM/DD
export function stringToDate(dateString: string) {
    let parts = dateString.split('/');
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1;
    let year = parseInt(parts[2], 10);
    return new Date(year, month, day);
}

// YYYY/MM/DD
export function dateToString(date: Date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('/');
}



export function validateProductType ({
    product_type,
    item_code,
    item_model,
    serial_number
  }: {
    product_type?: string
    serial_number: string
    item_model: string
    item_code: string
  }) {
    let value = ''
    if (!product_type) {
      value =
        (serial_number || '').trim().length !== 0 &&
        (item_model || '').trim().length !== 0 &&
        (item_code || '').trim().length !== 0
          ? PRODUCT_TYPE_HMI_Y_ACCESSORY // HMI & Accessory
          : (item_model || '').trim().length !== 0
          ? PRODUCT_TYPE_INDUSTRIAL_PC // Industrial PC
          : PRODUCT_TYPE_OTHER // Other
    } else {
      value = product_type
    }
    return value
}