import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Service from '../../../service/sendRequest'
// import generatePdfInformation from "./generate-pdf-information-rma"
import GeneratePDF from './generate-pdf-rma'
import { transformBase64ImageToBlob } from '../../../util/util'
import { Spin, Button, Modal } from 'antd'
import { iInformationPrint, iRMA } from '../../../util/types'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import useFetch from '../../../hook/useFetch'
import PrintHTML from './print-html'
// import { useOnMountUnsafe } from '../../../hook/useOnMountUnsafe'

// // * TEST-date
// import response from './data.json'
// const loading = false

type props = {
  idPostSelected: number
  isAdmin?: boolean
}

type responseService = {
  data?: iInformationPrint
}

export default function ViewerPdf ({ idPostSelected, isAdmin = false }: props) {
  const [showHTMLPrint, setShowHTMLPrint] = useState(false)
  const [isShowModalPrint, setIsShowModalPrint] = useState(false)

  const { t } = useTranslation()
  const {
    data: response,
    loading,
    // update,
    setData
  } = useFetch<responseService | null>(
    isAdmin ? Service.printRMAPostAdmin : Service.printRMAPost,
    idPostSelected,
    null
  )
  const [downloadDocument, setDownloadDocument] = useState<any>()
  const iframeRef = useRef<any>()
  const linkRef = useRef<any>()

  useEffect(() => {
    handlePrint()
  }, [response, iframeRef.current])

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      if (!downloadDocument || !iframeRef.current?.src)
        setIsShowModalPrint(() => true)
      // }, 10)
    }, 5000)
    return () => clearTimeout(currentTimeout)
  }, [iframeRef.current, downloadDocument])

  const handlePrintWeb = async () => {
    // await update()
    const response = await Service.printRMAPostAdaptiveWeb(idPostSelected)
    if (!response.error) setData(() => response)
    setShowHTMLPrint(() => true)
  }

  const handlePrint = async () => {
    try {
      if (!response?.data || (!iframeRef.current && loading)) return

      const blobQRCodeRMA = transformBase64ImageToBlob(
        response.data.rma_qr_code.image,
        response.data.rma_qr_code.contentType
      )
      const blobQRCodeSerial = response.data.serial_number_qr_code
        ? transformBase64ImageToBlob(
            response.data.serial_number_qr_code.image,
            response.data.serial_number_qr_code.contentType
          )
        : null

      const pdf = new GeneratePDF()

      await pdf
        .generatePdfInformation({
          qrCodeRMABlob: blobQRCodeRMA,
          serialNumberQRCodeBlob: blobQRCodeSerial,
          destination: response.data.destination,
          rma: response.data.rma as iRMA,
          rma_center: response.data.rma_center,
          attachments: response.data.attachments,
          user: response.data.user
        })
        .then(async () => {
          // ** DOWNLOAD
          const downloadDocument = await pdf.getUint8Array()
          setDownloadDocument(() => downloadDocument)
          // ** PREVIEW
          const pdfBase64 = await pdf.getBase64()
          iframeRef.current.src = pdfBase64
        })
    } catch (error) {
      // console.log('error: ',error)
    }
  }

  const handleDownloadPDF = () => {
    if (!downloadDocument) return
    try {
      const blob = new Blob([downloadDocument], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      linkRef.current.href = url
      linkRef.current.download = `RMA_${response?.data?.rma.id_rma}.pdf`
      linkRef.current.click()
    } catch (error) {
      // console.log('handleDownloadPDF: ',error)
    }
  }

  if (showHTMLPrint && !loading)
    return (
      <PrintHTML
        destination={response?.data?.destination as any}
        attachments={response?.data?.attachments as any}
        rma={response?.data?.rma as iRMA}
        rma_center={response?.data?.rma_center as any}
        user={response?.data?.user as any}
      />
    )

  return (
    <div className='h-screen overflow-y-hidden mb-5'>
      <div className='px-2 sm:px-0 py-5 select-none flex flex-col'>
        <Button
          className='disabled:hover:bg-none uppercase bg-customColor-exor_blue hover:bg-customColor-exor_blue/80'
          type='primary'
          disabled={!downloadDocument}
          onClick={() => handleDownloadPDF()}
          // className="uppercase .underline cursor-pointer disabled:cursor-not-allowed rounded-sm p-1 bg-customColor-exor_blue text-white hover:text-white/80 active:text-white/90 disabled:opacity-50"
        >
          {t('page.manager.print.p2')}
        </Button>
        <span className='text-gray-500 pt-2'>
          {t('page.manager.print.download_error_msg')}
          {', '}
          <span
            onClick={() => handlePrintWeb()}
            className='cursor-pointer font-bold underline hover:text-customColor-exor_blue'
          >
            {t('page.manager.print.click_here')}
          </span>
          .
        </span>
      </div>
      {loading ? (
        <div className='relative h-screen flex justify-center items-center  w-full bg-slate-50'>
          <div className='z-10 flex gap-5 flex-col justify-center items-center'>
            <Spin
              indicator={
                <Loading3QuartersOutlined style={{ fontSize: 48 }} spin />
              }
            />
          </div>
          <div className='z-0 h-full w-full absolute animate-pulse bg-gray-100'></div>
        </div>
      ) : (
        <iframe
          ref={iframeRef}
          className={`z-10 rounded-lg w-full h-full sm:h-full`}
          title='print-rma'
          id='print-pdf'
          typeof='application/pdf'
        />
      )}
      <a ref={linkRef} className='hidden' />
      <Modal
        title={t('page.ticket.word.information')}
        okText={t('message.tooltip.print')}
        open={isShowModalPrint}
        onOk={() => handlePrintWeb()}
        cancelText={t('btn.confirmation.cancel')}
        onCancel={() => setIsShowModalPrint(() => false)}
      >
        <p>{t('page.manager.print.message')}</p>
      </Modal>
    </div>
  )
}